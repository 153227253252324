import React from 'react'
import PropTypes from 'prop-types'

PriceLevelSelector.propTypes = {
  priceLevels: PropTypes.array.isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export function PriceLevelSelector({ priceLevels, selected, onChange }) {
  return (
    // TODO: Dane! Help!
    // eslint-disable-next-line jsx-a11y/no-onchange
    <select name="priceLevel" value={selected} onChange={onChange}>
      <option value="">None</option>
      {priceLevels.map((pl) => (
        <option key={pl.id} value={pl.id}>
          {pl.name}
        </option>
      ))}
    </select>
  )
}

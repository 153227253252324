import React, { useEffect, useState } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import * as R from 'ramda'

import { EnsureRole } from '../../components/admin/ensure-role'

import { UserTable } from '../../components/users-table'
import { Pager } from '../../components/users-table/pager'

const ROWS_PER_PAGE = 50

const USERS_TO_APPROVE = gql`
  query UsersToApprove(
    $first: Int
    $offset: Int
    $filter: UserFilter
    $orderBy: [UsersOrderBy!]
  ) {
    needsApproval(
      first: $first
      offset: $offset
      orderBy: $orderBy
      filter: $filter
    ) {
      totalCount
      nodes {
        city
        company
        createdAt
        email
        firstName
        id
        lastName
        phone
        priceLevel {
          id
          name
        }
        state
        website
      }
    }

    priceLevels {
      nodes {
        id
        name
      }
    }
  }
`

const ASSIGN_PRICE_LEVEL = gql`
  mutation AssignPriceLevel($input: UserPriceLevel!) {
    assignUserPriceLevel(input: $input) {
      message
    }
  }
`

const { NONE, ASC, DESC } = { NONE: null, ASC: 'asc', DESC: 'desc' }

const createOrderBy = (columnName) => (orderByDirection) => {
  return {
    orderBy: R.join('_', [R.toUpper(columnName), R.toUpper(orderByDirection)]),
  }
}

const changeDirection = (currentDirection) => {
  if (currentDirection === DESC) return ASC
  if (currentDirection === ASC) return NONE
  if (currentDirection === NONE) return DESC
}

const buildVariables = (field) => (orderByDirection) => {
  return field === null
    ? null
    : orderByDirection
    ? createOrderBy(field)(orderByDirection)
    : null
}

export default function UsersPage() {
  const { data, loading, refetch } = useQuery(USERS_TO_APPROVE, {
    first: ROWS_PER_PAGE,
  })
  const [assignPriceLevel] = useMutation(ASSIGN_PRICE_LEVEL)
  const [orderByField, setOrderByField] = useState('created_at')
  const [orderByDirection, setOrderByDirection] = useState(DESC)
  const [offset, setOffset] = useState(0)
  const [selectedPage, setSelectedPage] = useState(0)

  useEffect(() => {
    const operation = async () => {
      const variables = buildVariables(orderByField)(orderByDirection)
      const updatedVariables = {
        ...variables,
        first: ROWS_PER_PAGE,
        offset,
      }
      refetch(updatedVariables)
    }
    operation()
  }, [orderByField, orderByDirection, offset])

  const handleSort = (field) => {
    const newDirection = changeDirection(orderByDirection)

    if (field !== orderByField) {
      setOrderByDirection(ASC)
    } else {
      setOrderByDirection(newDirection)
    }

    setOrderByField(field)
  }

  const handleOnPage = (p, o) => {
    setOffset(o)
    setSelectedPage(p)
  }

  const count = R.pathOr('0', ['needsApproval', 'totalCount'])(data)

  return (
    <EnsureRole
      userRole="client-admin"
      protectedContent={() => (
        <>
          <h2>All Users</h2>
          <Pager
            total={count}
            rowsPerPage={ROWS_PER_PAGE}
            onPage={handleOnPage}
            selectedPage={selectedPage}
          />
          <UserTable
            users={data && data.needsApproval && data.needsApproval.nodes}
            priceLevels={data && data.priceLevels && data.priceLevels.nodes}
            assignPriceLevel={assignPriceLevel}
            refetch={refetch}
            loading={loading}
            onSort={handleSort}
            orderByField={orderByField}
            orderByDirection={orderByDirection}
          />
          <Pager
            total={count}
            rowsPerPage={ROWS_PER_PAGE}
            onPage={handleOnPage}
            selectedPage={selectedPage}
          />
        </>
      )}
    />
  )
}

import PropTypes from 'prop-types'
import React from 'react'
import { gql, useMutation } from '@apollo/client'

import * as styles from './index.module.css'

import { HeaderColumnFilter } from './header-column-filter'
import { PriceLevelSelector } from './price-level-selector'

const DELETE_USER = gql`
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      user {
        id
      }
    }
  }
`

const Delete = ({ onClick, loading }) => {
  return (
    <button
      onClick={() =>
        window.confirm(
          'Are you sure you wish to PERMANENTLY delete this user (cannot be undone)?',
        )
          ? onClick('confirm')
          : () => {}
      }
      disabled={loading}
    >
      Delete
    </button>
  )
}

Delete.propTypes = {
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

export function UserTable({
  users,
  priceLevels,
  assignPriceLevel,
  loading,
  refetch,
  onSort,
  orderByField,
  orderByDirection,
}) {
  if (!users) return null

  const [deleteUser, { loading: deleteLoading }] = useMutation(DELETE_USER, {
    refetchQueries: [`UsersToApprove`],
  })

  const handlePriceLevelChange = (userId) => {
    return async (e) => {
      await assignPriceLevel({
        variables: { input: { userId, priceLevelId: e.target.value || null } },
      })
      refetch()
    }
  }

  const handleDelete = (id) => async () => {
    try {
      await deleteUser({ variables: { input: { id } } })
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <table className={styles.users}>
      <thead>
        <tr>
          <th>
            <HeaderColumnFilter
              label="Company"
              onSort={onSort}
              orderByField={orderByField}
              orderByDirection={orderByDirection}
              fieldName="company"
            />
          </th>
          <th>Website</th>
          <th>Name</th>
          <th>
            <HeaderColumnFilter
              label="Email"
              onSort={onSort}
              orderByField={orderByField}
              orderByDirection={orderByDirection}
              fieldName="email"
            />
          </th>
          <th>City</th>
          <th>State</th>
          <th>Phone</th>
          <th>
            <HeaderColumnFilter
              label="Registration Date"
              onSort={onSort}
              orderByField={orderByField}
              orderByDirection={orderByDirection}
              fieldName="created_at"
            />
          </th>
          <th>Price Level</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody>
        {!loading &&
          users.map((u) => (
            <tr
              key={u.id}
              className={
                u.priceLevel ? styles[u.priceLevel && u.priceLevel.name] : ''
              }
            >
              <td>{u.company}</td>
              <td>{u.website}</td>
              <td>
                {u.firstName} {u.lastName}
              </td>
              <td>{u.email}</td>
              <td>{u.city}</td>
              <td>{u.state}</td>
              <td>{u.phone}</td>

              <td>{new Date(u.createdAt).toDateString()}</td>
              <td>
                <PriceLevelSelector
                  onChange={handlePriceLevelChange(u.id)}
                  priceLevels={priceLevels || []}
                  selected={(u.priceLevel && u.priceLevel.id) || ''}
                />
              </td>
              <td>
                <Delete onClick={handleDelete(u.id)} loading={deleteLoading} />
              </td>
            </tr>
          ))}
        {!loading && users.length === 0 && (
          <tr colSpan="9">
            <td colSpan="9">
              <div className={styles.noResults}>
                All pending users have been assigned!
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

UserTable.propTypes = {
  assignPriceLevel: PropTypes.func,
  loading: PropTypes.any,
  onSort: PropTypes.any,
  orderByDirection: PropTypes.any,
  orderByField: PropTypes.any,
  priceLevels: PropTypes.array,
  refetch: PropTypes.func,
  users: PropTypes.array,
}

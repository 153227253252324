// TODO: Dane, help
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import PropTypes from 'prop-types'

import * as styles from './header-column-filter.module.css'

const { NONE, ASC } = { NONE: null, ASC: 'asc', DESC: 'desc' }

export function SortDirection({ direction = null }) {
  const arrow = direction === NONE ? '' : direction === ASC ? '△' : '▽'

  return <div className={styles.sortDirection}>{arrow}</div>
}

SortDirection.propTypes = {
  direction: PropTypes.any,
}

export function HeaderColumnFilter({
  label,
  fieldName,
  orderByField,
  orderByDirection,
  onSort,
}) {
  const isSelected = fieldName === orderByField && orderByDirection

  const direction = isSelected ? orderByDirection : NONE

  return (
    <div
      className={`${styles.headerColumnFilter}`}
      onClick={() => onSort(fieldName)}
    >
      <span>{label}</span>
      <SortDirection onSort={onSort} direction={direction} />
    </div>
  )
}

HeaderColumnFilter.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onSort: PropTypes.func,
  orderByDirection: PropTypes.any,
  orderByField: PropTypes.any,
}

import React from 'react'
import * as styles from './pager.module.css'

export const Pager = ({ total, rowsPerPage, onPage, selectedPage }) => {
  const numberOfPages = Math.ceil(total / rowsPerPage)
  const pages = []

  for (let page = 0; page < numberOfPages; ++page) {
    pages.push([page, page * rowsPerPage])
  }

  const handleClick = (p, offset) => (e) => {
    onPage(p, offset)
  }

  return (
    <ul className={styles.pager}>
      {pages.map(([page, offset]) => (
        <li key={page} className={selectedPage === page ? styles.selected : ''}>
          <button onClick={handleClick(page, offset)}>{page + 1}</button>
        </li>
      ))}
    </ul>
  )
}
